import React, { useEffect, useState } from "react";
import './AboutUs.css'
import axios from "axios";
const AboutUs = () =>{
  const [aboutUsPunchline,setAboutUsPunchline] = useState('About Uss');
  const [aboutUsText,setAboutUsText] = useState('At Endeavr, our mission is to empower organizations with customized IT solutions, enabling them to thrive in a digital-first world');
  const [about,setAbout] = useState([])
  useEffect(() => {
      axios.get('https://admin.endeavr.info/api/about')
        .then(response => {
          response.data.about.forEach(item => {
            setAboutUsPunchline(item.aboutUsPunchline);
            setAboutUsText(item.aboutUsText);
          })
            
          
          
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }, []);

    return(
      <div className="container_aboutus">
        <div className='aboutus_punchline'>
            {aboutUsPunchline}
        </div>
        <div className='aboutus_text'>
            {aboutUsText}
        </div> 
      </div>
    );
}
export default AboutUs;