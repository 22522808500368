import './Clients.css'
import React, { useState, useEffect, useRef } from "react";
import logo from'../../assets/logo.svg';
import lohana_enterprises from'../../assets/client_logos/lohana_enterprises.svg';
import south_corporation from'../../assets/client_logos/south_corporation.svg';
import sulco from'../../assets/client_logos/sulco.svg';
import axios from 'axios';
import ScrollTrigger from 'gsap/ScrollTrigger';
import gsap from "gsap";
const globalpath = 'https://admin.endeavr.info/';
// const Clients = () =>{
//   const [clientspunchline, setclientspunchline]= useState('We believe each client is a long term partnership');
//   const [array1, setArray1] = useState([lohana_enterprises, south_corporation, sulco]);//clientlogo
//   const logosSlideRef = useRef(null); // Reference to the logos slide div

//   useEffect(() => {
//     axios.get('http://localhost:8000/get_landing_data')
//       .then(response => {
//         setclientspunchline(response.data.clientspunchline);
//         setArray1(response.data.array1)
//         const copy = logosSlideRef.current.cloneNode(true);
//         const logosContainer = document.querySelector(".logos");
//         logosContainer.appendChild(copy);

//       })
//       .catch(error => {
//         console.error('Error fetching data:', error);
//       });
//   }, []);

//     return(
//       <div className='container_client'>

//         <div className='client_punchline'>
//           {clientspunchline}
//         </div>
//         <div className="client_logos">
//           <div className="client_logos_slide" ref={logosSlideRef}>
//             {array1.map((logo, index) => (<img key={index} src={logo} alt={`Logo ${index}`} />))}
//           </div>
//         </div>
//     </div>
//     );
// }
// export default Clients;



export default function Clients() {

   const [clientspunchline, setclientspunchline]= useState('We believe each client is a long term partnership');
   const [array1, setArray1] = useState([lohana_enterprises, south_corporation, sulco]);//clientlogo
   const logosSlideRef = useRef(null); // Reference to the logos slide div

   
useEffect(() => {
  axios.get('https://admin.endeavr.info/api/clients')
    .then(response => {
      console.log(response.data);
      const clients = response.data.clients;
      if (clients && clients.length > 0) {
        // Extracting clients punchline
        clients.forEach(client => {
          const punchline = client.clientspunchline;
          if (punchline) {
            setclientspunchline(punchline);
            // Break the loop after setting the punchline
            return;
          }
        });

        // Extracting array1 values
        const logos = [];
        clients.forEach(client => {
          if (client.array1) {
            logos.push(globalpath.concat(client.array1));
          }
        });
        setArray1(logos);
          loop = horizontalLoop(".client_images", { speed: 1, repeat: -1, paddingRight: 25 });
          gsap.registerPlugin(ScrollTrigger);
          //triggers on scroll event 
          window.addEventListener('scroll', handleScroll);
          return () => {
            window.removeEventListener('scroll', handleScroll);
          };
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
    
}, []);

  let loop;
  const imageContainerRef = useRef(null);
    function setDirection(value) {
      if (loop.direction !== value) {
        gsap.to(loop, { timeScale: value, duration: 0.3, overwrite: true });
        loop.direction = value;
      }
    }

    const handleScroll = () => {
      setDirection(window.scrollY > 0 ? 1 : -1);
    };

  // useEffect(() => {
  //   loop = horizontalLoop(".client_images", { speed: 1, repeat: -1, paddingRight: 25 });
  //   gsap.registerPlugin(ScrollTrigger);
  //   //triggers on scroll event 
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  function horizontalLoop(items, config) {
    items = gsap.utils.toArray(items);
    console.log("tems",config)
    //configuration object 
    config = config || {};
    //setting in movements and styles 
    let tl = gsap.timeline({ repeat: config.repeat, paused: config.paused, defaults: { ease: "none" }, onReverseComplete: () => tl.totalTime(tl.rawTime() + tl.duration() * 100) }),
      length = items.length,
      startX = items[0].offsetLeft,
      times = [],
      widths = [],
      xPercents = [],
      curIndex = 0,
      pixelsPerSecond = (config.speed || 1) * 100,
      snap = config.snap === false ? v => v : gsap.utils.snap(config.snap || 1),
      totalWidth, curX, distanceToStart, distanceToLoop, item, i;
    gsap.set(items, {
      xPercent: (i, el) => {
        let w = widths[i] = parseFloat(gsap.getProperty(el, "width", "px"));
        xPercents[i] = snap(parseFloat(gsap.getProperty(el, "x", "px")) / w * 100 + gsap.getProperty(el, "xPercent"));
        return xPercents[i];
      }
    });
    gsap.set(items, { x: 0 });
    totalWidth = items[length - 1].offsetLeft + xPercents[length - 1] / 100 * widths[length - 1] - startX + items[length - 1].offsetWidth * gsap.getProperty(items[length - 1], "scaleX") + (parseFloat(config.paddingRight) || 0);
    for (i = 0; i < length; i++) {
      item = items[i];
      curX = xPercents[i] / 100 * widths[i];
      distanceToStart = item.offsetLeft + curX - startX;
      distanceToLoop = distanceToStart + widths[i] * gsap.getProperty(item, "scaleX");
      tl.to(item, { xPercent: snap((curX - distanceToLoop) / widths[i] * 100), duration: distanceToLoop / pixelsPerSecond }, 0)
        .fromTo(item, { xPercent: snap((curX - distanceToLoop + totalWidth) / widths[i] * 100) }, { xPercent: xPercents[i], duration: (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond, immediateRender: false }, distanceToLoop / pixelsPerSecond)
        .add("label" + i, distanceToStart / pixelsPerSecond);
      times[i] = distanceToStart / pixelsPerSecond;
    }
    function toIndex(index, vars) {
      vars = vars || {};
      (Math.abs(index - curIndex) > length / 2) && (index += index > curIndex ? -length : length);
      let newIndex = gsap.utils.wrap(0, length, index),
        time = times[newIndex];
      if (time > tl.time() !== index > curIndex) {
        vars.modifiers = { time: gsap.utils.wrap(0, tl.duration()) };
        time += tl.duration() * (index > curIndex ? 1 : -1);
      }
      curIndex = newIndex;
      vars.overwrite = true;
      return tl.tweenTo(time, vars);
    }
    tl.next = vars => toIndex(curIndex + 1, vars);
    tl.previous = vars => toIndex(curIndex - 1, vars);
    tl.current = () => curIndex;
    tl.toIndex = (index, vars) => toIndex(index, vars);
    tl.times = times;
    tl.progress(1, true).progress(0, true);
    if (config.reversed) {
      tl.vars.onReverseComplete();
      tl.reverse();
    }
    return tl;
  }

  return (
    <>
    <div className='container_client'>

      <div className='client_punchline'>
        {clientspunchline}
      </div>
      <div className="client_wrapper">
        <div className="client_imageContainer" ref={imageContainerRef}>
          {array1.map((logo, index) => (<img className='client_images' key={index} src={logo} alt={`Logo ${index}`} />))}
        </div>
      </div>
    </div>
    </>
  );
}