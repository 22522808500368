import axios from 'axios';
import './Landing.css'
import React, { useState, useEffect } from "react";
import video from '../../assets/purplebackground.mp4';
import logo from '../../assets/logo.svg';
import ContactUs from '../contact/ContactUs';

const Landing = ({ scrollToAboutUs}) => {
  const globalpath = 'https://admin.endeavr.info/';
  const [companyLogo, setCompanyLogo]= useState(logo);
  const [landingPunchline, setLandingPunchline] = useState('Empowering Businesses through Digital Transformation');
  const [fronttext,setfronttext] = useState([])
  useEffect(() => {
    axios.get('https://admin.endeavr.info/api/fronttext')
    .then(response => {
      
      response.data.fronttext.forEach(item => {
        setCompanyLogo(globalpath.concat(item.companyLogo));
        setLandingPunchline(item.landingPunchline);
      })
    })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    
    <div className='main_screen'>
      <video autoPlay loop muted>
        <source src={video} />
      </video>
      <div className='landing_subcontainer'>
        <div className='landing_container_content'>
          <div className='landing_company_logo'>
            <img src={companyLogo} alt="no image fetched" />
          </div>
          <div className='landing_punchline'>
            {landingPunchline}
          </div>
        </div>
        
        <div className='scrolldown' onClick={scrollToAboutUs}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
}

export default Landing;
