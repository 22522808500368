import './Services.css';
import React, { useState, useEffect } from "react";
import erp_2 from '../../assets/services/erp_2.svg'
import rm_1 from '../../assets/services/rm_1.svg'
import app_3 from '../../assets/services/app_3.svg'
import software_1 from '../../assets/services/software_1.svg'
import crm from '../../assets/services/crm.svg'
import pos_1 from '../../assets/services/pos_1.svg'
import lm_2 from '../../assets/services/lm_2.svg'
import web_3 from '../../assets/services/web_3.svg'
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; 

const Services = () => {
    const [servicePunchline, setServicePunchline] = useState('eeWe offer a range of Products and Services to meet your Needs');
    const [serviceHeading, setServiceHeading] = useState('Services');
    const [serviceArray1, setServiceArray1] = useState([app_3,software_1,web_3]); // servicelogo 
    const [serviceArray2, setServiceArray2] = useState(['mobile app development', 'software development', 'web development']); // servicename
    const [productHeading, setProductHeading] = useState('Products');
    const [productArray1, setProductArray1] = useState([erp_2,crm,pos_1,rm_1,lm_2]); // productlogo 
    const [productArray2, setProductArray2] = useState(['ERP', 'CRM', 'point of sale','restaurant management','learning management']); // productename
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1025); // Initialize isMobile state
    const globalpath = 'https://admin.endeavr.info/';
    useEffect(() => {
        axios.get('https://admin.endeavr.info/api/services')
          .then(response => {
            const data = response.data.all_data;
            
            // Extracting punchline, service heading, and product heading
            if (data && data.service) {
              const serviceData = data.service;
              serviceData.forEach(item => {
                if (item.servicePunchline) setServicePunchline(item.servicePunchline);
                if (item.serviceHeading) setServiceHeading(item.serviceHeading);
                if (item.productHeading) setProductHeading(item.productHeading);
              });
            }
            if (data && data.product) {
                const serviceData = data.service;
                serviceData.forEach(item => {
                  if (item.servicePunchline) setServicePunchline(item.servicePunchline);
                  if (item.serviceHeading) setServiceHeading(item.serviceHeading);
                  if (item.productHeading) setProductHeading(item.productHeading);
                });
              }
            
            // Extracting service data
            const serviceLogos = [];
            const serviceNames = [];
            if (data && data.service) {
              const serviceData = data.service;
              serviceData.forEach(item => {
                if (item.serviceArray1) serviceLogos.push(globalpath.concat(item.serviceArray1));
                if (item.serviceArray2) serviceNames.push(item.serviceArray2);
              });
            }
            
            // Extracting product data
            const productLogos = [];
            const productNames = [];
            if (data && data.product) {
              const productData = data.product;
              productData.forEach(item => {
                if (item.productArray1) productLogos.push(globalpath.concat(item.productArray1));
                if (item.productArray2) productNames.push(item.productArray2);
              });
            }
            
            // Set state variables
            setServiceArray1(serviceLogos);
            setServiceArray2(serviceNames);
            setProductArray1(productLogos);
            setProductArray2(productNames);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }, []);
      

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1025);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='container_services'>
            <div className='container_content'>
                <div className='service_punchline'>
                    {servicePunchline}
                </div>
                    {/* Conditional rendering of service items based on screen width */}
                    {isMobile ? (
                        <div className='carousel_container'>
                            <div className='service_text'> {serviceHeading} </div>
                            <Carousel showThumbs={false} autoPlay={true} interval={4000} infiniteLoop={true} showArrows={false} showStatus={false} stopOnHover={true} showIndicators={false}>
                                {serviceArray1.map((item, index) => (
                                    <div key={index} className='service_carousel_card'>
                                        <div>
                                            <img src={item} alt={`Logo ${index}`} />
                                        </div>
                                        <div className='service_card_text'>{serviceArray2[index]}</div>
                                    </div>
                                    
                                ))}
                            </Carousel>
                        </div>
                    ) : (
                        <div className='c_container'>
                            <div className='service_text'> {serviceHeading} </div>
                            <div className='service_card_container'>
                                
                                {serviceArray1.map((item, index) => (
                                    <div key={index} className='service_card'>
                                        <div className='service_card_icon'>
                                            <img src={item} alt={`Logo ${index}`} />
                                        </div>
                                        <div className='service_card_text'>{serviceArray2[index]}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                
                {/* Conditional rendering of product items based on screen width */}
                    {isMobile ? (
                        <div className='carousel_container'>
                            <div className='service_text'> {productHeading} </div>
                        <Carousel showThumbs={false} autoPlay={true} interval={4000} infiniteLoop={true} showArrows={false} showStatus={false} stopOnHover={true} showIndicators={false}>
                            {productArray1.map((item, index) => (
                                <div key={index} className='service_carousel_card'>
                                    <div>
                                        <img src={item} alt={`Logo ${index}`} />
                                    </div>
                                    <div className='service_card_text'>{productArray2[index]}</div>
                                </div>
                                
                            ))}
                        </Carousel>
                        </div>
                    ) : (
                        <div className='c_container'>
                            <div className='service_text'> {productHeading} </div>
                            <div className='service_card_container'>
                                {productArray1.map((item, index) => (
                                    <div key={index} className='service_card'>
                                        <div className='service_card_icon'>
                                            <img src={item} alt={`Logo ${index}`} />
                                        </div>
                                        <div className='service_card_text'>{productArray2[index]}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                </div>
            
    );
}

export default Services;
