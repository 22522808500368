
import './App.css';
import Main from './main/Main';

function App() {
  return (
    <div className='apps'>
      <Main/>
    </div>
  );
}
export default App;