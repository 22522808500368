
import './Main.css';
import Services from '../components/services/Services';
import AboutUs from "../components/aboutus/AboutUs";
import Clients from "../components/clients/Clients";
import Landing from "../components/landing/Landing";
import ContactUs from "../components/contact/ContactUs";
import Projects from "../components/projects/Projects";
import NavButton from "../components/navButton/NavButton";



import React from 'react';
const Main = () =>{
  const scrollToAboutUs = () => {
    const aboutUsContainer = document.querySelector('.container_aboutus');
    if (aboutUsContainer) {
        aboutUsContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
};
  return(
      
    <div>
      <div className="container-main">
        <Landing scrollToAboutUs={scrollToAboutUs} />
        <AboutUs/>
        <Services/>
        <Clients/>
        <Projects/>
        <ContactUs/>
      </div>
    </div>
    
  
);
}

export default Main;