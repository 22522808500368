import './ContactUs.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import gmail_logo from '../../assets/social_logos/email.svg';
import insta_logo from '../../assets/social_logos/instagram_logo_6.svg';
import facebook_logo from '../../assets/social_logos/facebook_logo_5.svg';
import linkedin_logo from '../../assets/social_logos/linkedin_logo_6.svg';

const ContactUs = () => {
  const [contactPunchline, setContactPunchline] = useState('Let\'s Work Together');
  const [emailAddress, setEmailAddress] = useState('hello@endeavr.info');
  const [emailAddressLogo, setEmailAddressLogo] = useState(gmail_logo);
  const [socialLogos, setSocialLogos] = useState([insta_logo, facebook_logo, linkedin_logo]);
  const [socialLinks, setSocialLinks] = useState(['https://www.instagram.com/endeavrco/', 'http://facebook.com/endeavrco', 'http://linkedin.com/company/endeavr/']);
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Message: '',
  });
  const globalpath = 'https://admin.endeavr.info/';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://admin.endeavr.info/api/fetchsocialmedia');
        const { data } = response;
  
        if (data && data.socialmedia) {
          const socialLogos = [];
          const socialLinks = [];
          let emailLogo = gmail_logo;
          let emailAddress = 'hello@endeavr.info';
  
          data.socialmedia.forEach(item => {
            if (item.title === 'email') {
              emailLogo = globalpath.concat(item.image);
              emailAddress = item.url;
            } else {
              socialLogos.push(globalpath.concat(item.image));
              socialLinks.push(item.url);
            }
          });
  
          // Set state variables
          setSocialLogos(socialLogos);
          setSocialLinks(socialLinks);
          setEmailAddressLogo(emailLogo);
          setEmailAddress(emailAddress);
        }
      } catch (error) {
        console.error('Error fetching social media data:', error);
      }
    };
  
    fetchData();
  }, []);
  


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Send formData to backend using axios POST request
      await axios.post('https://admin.endeavr.info/api/store', formData);
      console.log('Form data sent successfully!');
    } catch (error) {
      console.log("data", formData);
      console.error('Error sending form data:', error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className='container_contact'>
      <div className='subcontainer_contactus'>
        <div className='cont_a'>
          <div className='cont_p'>{contactPunchline}</div>
          <div className='cont_t'>
            <a href={emailAddress}><img src={emailAddressLogo} alt='insta_logo' className='img_email' /></a> {emailAddress}
          </div>
          <div className='social_logos'>
            {socialLogos.map((logo, index) => (
              <a href={socialLinks[index].url} key={index}>
                <img src={logo} alt='logo' className='logoimg' />
              </a>
            ))}
          </div>
          <div className="copyrights">© 2024 ENDEAVR. All Rights reserved</div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="inputbox">
            <input type="text" name="Name" value={formData.Name} onChange={handleChange} placeholder="Name" required />
          </div>
          <div className="inputbox">
            <input type="email" name="Email" value={formData.Email} onChange={handleChange} placeholder='Email' required />
          </div>
          <div className="inputbox">
            <input type="message" minLength="3" maxLength="200" name="Message" value={formData.Message} onChange={handleChange} placeholder='Your Message' required />
          </div>
          <div className="inputbox">
            <input type="submit" value="Submit" />
          </div>
        </form>
        <div className="copyrights_l">© 2024 ENDEAVR. All Rights reserved</div>
      </div>
    </div>
  );
};

export default ContactUs;
