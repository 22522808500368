import './Projects.css'
import React, { useState, useEffect } from "react";
import axios from 'axios';
import logo1 from'../../assets/projects/ERP and Accounting MOCKUP.png';
import logo2 from'../../assets/projects/POS mockup.png';
import { FreeMode, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; 
import { Swiper, SwiperSlide } from "swiper/react";
const Projects = () =>{

  const [projectPunchline,setProjectPunchline] = useState('Projects');
  const [array1, setArray1] = useState([logo1,logo2,logo1]);//projectlogo 
  const [array2, setArray2] = useState(['ERP and Accounting for Sulco group','Point Of Sale','Learning Management']);//projectname
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1025); // Initialize isMobile state
  useEffect(() => {
      axios.get('http://localhost:8000/get_landing_data')
        .then(response => {
          setProjectPunchline(response.data.projectPunchline);
          setArray1(response.data.array1);
          setArray2(response.data.array2);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }, []);

    useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 1025);
      };

      window.addEventListener('resize', handleResize);

      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);
  return(
    <div className='container_projects'>

      <div className='project_punchline'>
          {projectPunchline}
      </div>
      {isMobile ? (
                        <div className='carousel_container'>
                            <Carousel showThumbs={false} autoPlay={true} interval={4000} infiniteLoop={true} showArrows={false} showStatus={false} stopOnHover={true} showIndicators={false}>   
                            {array1.map((item, index) => (
                                <div key={index} className='project_image_container'>
                                  <img src={item} alt="project_img" className='image'/>
                                          <div className='overlay'>
                                            <div className='text_project'>{array2[index]}</div>
                                          </div>
                                          </div>
                              ))}
                            </Carousel>
                        </div>
                    ) : (
                      <div className="swiper-container">
                      <Swiper
                        breakpoints={{
                          769: {
                            slidesPerView: 2.5,
                            spaceBetween: 225,
                          },
                          1280: {
                            slidesPerView: 2.5,
                            spaceBetween: 225,
                          },
              
                          358: {
                            slidesPerView: 1.05,
                            spaceBetween: 225,
                          }
                        }}
                        freeMode={true}
                        modules={[FreeMode, Pagination]}
                        
                        className='swiper'
                      >
                        {array1.map((item, index) => (
                          <SwiperSlide key={index} className='abc'>
                            <div className='project_image_container'>
                            <img src={item} alt="project_img" className='image'/>
                                    <div className='overlay'>
                                      <div className='text_project'>{array2[index]}</div>
                                    </div>
                                    </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                    )}
    </div>
  );
}
export default Projects;